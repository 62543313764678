import trees from '@/data/trees.json';
import { getViableTrees, Tree } from '@/lib/connectors/trees';
import { useTerrainData } from '@/lib/data/index';
import { useMemo } from 'react';

export function useTrees(): { availableTrees: Tree[] } {
  const terrainData = useTerrainData();

  const availableTrees = useMemo(
    () =>
      getViableTrees({
        hardiness: terrainData.hardiness,
        sun: terrainData.sun,
        soilHumidity: terrainData.soilHumidity,
      }),
    [terrainData]
  );

  return { availableTrees };
}

export function useTree(treeName: string): Tree {
  return trees.find((t) => t.tree === treeName);
}
