import { HeaderLink } from '@/components/Header';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, Fragment } from 'react';

type BreadcrumbsProps = {
  links: HeaderLink[];
  currentPage: string;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ links, currentPage }) => {
  return (
    <div className="flex space-x-4">
      <Link to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </Link>
      {links.map((link) => (
        <Fragment key={link.src}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
          <Link to={link.src}>{link.text}</Link>
        </Fragment>
      ))}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
      <div>{currentPage}</div>
    </div>
  );
};

export default Breadcrumbs;
