import { uuid } from '@/lib/uuid';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

export type TreeGuild = {
  id?: string;
  location?: {
    x: number;
    y: number;
  };
  tree?: string;

  variety?: string;
  soulmate?: string | null;

  plants: string[];
};

export function useTreeGuilds(): {
  guilds: TreeGuild[];
  saveGuild: (guild: TreeGuild) => Promise<TreeGuild>;
  deleteGuild: (guildId: TreeGuild['id']) => Promise<unknown>;
} {
  const [guildsStorage, setGuildsStorage] = useLocalStorage<{
    [key: string]: TreeGuild;
  }>('treeGuilds', {});

  const saveGuild = useCallback(
    async (guild: TreeGuild) => {
      const guildToSave = { ...guild, id: guild.id ?? uuid() };
      setGuildsStorage({ ...guildsStorage, [guildToSave.id]: guildToSave });
      return guildToSave;
    },
    [guildsStorage, setGuildsStorage]
  );

  const deleteGuild = useCallback(
    async (guildId: TreeGuild['id']) => {
      setGuildsStorage(
        Object.entries(guildsStorage)
          .filter(([id]) => id !== guildId)
          .reduce((acc, it) => ({ ...acc, [it[0]]: it[1] }), {})
      );
    },
    [guildsStorage, setGuildsStorage]
  );

  return {
    guilds: Object.values(guildsStorage).reverse(),
    saveGuild,
    deleteGuild,
  };
}

export function useTreeGuild(guildId: TreeGuild['id']): {
  guild?: TreeGuild;
  updateGuild: (guild: TreeGuild) => Promise<TreeGuild>;
  deleteGuild: () => Promise<unknown>;
} {
  const treeGuildsData = useTreeGuilds();

  const deleteGuild = useCallback(
    async () => treeGuildsData.deleteGuild(guildId),
    [treeGuildsData]
  );

  return {
    guild: treeGuildsData.guilds.find((it) => it.id === guildId),
    updateGuild: treeGuildsData.saveGuild,
    deleteGuild,
  };
}
