import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { Tree, Variety } from '@/lib/connectors/trees';
import { TreeGuild, useTreeGuild } from '@/lib/data/treeGuild';
import { useTrees } from '@/lib/data/trees';
import clsx from 'clsx';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import { useSearchParam } from 'react-use';

const TreeGuildPage: FC<PageProps> = () => {
  const guildId = useSearchParam('guildId');
  const x = useSearchParam('x');
  const y = useSearchParam('y');
  const treeGuildData = useTreeGuild(guildId);
  const trees = useTrees();
  const isNewGuild = !treeGuildData.guild?.tree;

  async function chooseTree(tree: Tree) {
    await treeGuildData.updateGuild({
      id: guildId,
      tree: tree.tree,
      location: {
        x: x ? parseInt(x, 10) : 0,
        y: y ? parseInt(y, 10) : 0,
      },
      plants: [],
    });
  }

  function renderCenterPiece() {
    const completeTree = trees.availableTrees.find(
      (t) => t.tree === treeGuildData.guild?.tree
    );
    return (
      <>
        <h2 className="text-xl font-bold my-4">Center Piece</h2>
        <div className="flex overflow-x-auto -mx-2 -my-2">
          {trees.availableTrees.map((tree) => (
            <button
              key={tree.tree}
              className={clsx(
                'shadow-lg rounded-md p-8 m-2 flex flex-col items-center cursor-pointer',
                treeGuildData.guild?.tree === tree.tree
                  ? 'bg-secondary text-white'
                  : 'bg-white'
              )}
              type="button"
              onClick={() => chooseTree(tree)}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center text-xl">{tree.tree}</div>
            </button>
          ))}
        </div>
        {completeTree?.warning &&
          completeTree.warning
            .split('*')
            .map((w) => w.trim())
            .filter(Boolean)
            .map((warning) => (
              <div key={warning} className="mt-4 text-gray-700">
                ⚠ {warning}
              </div>
            ))}
      </>
    );
  }

  async function chooseVariety(guild: TreeGuild, variety: Variety) {
    await treeGuildData.updateGuild({
      ...guild,
      variety: variety.name,
    });
  }

  function renderVariety() {
    const treeName = treeGuildData.guild?.tree;
    const completeTree = trees.availableTrees.find((t) => t.tree === treeName);
    if (!treeName || !completeTree || !completeTree.varieties.length)
      return null;

    return (
      <>
        <h2 className="text-xl font-bold mt-10 mb-4">
          {treeName} Variety
          {completeTree.pollinationMedium.some((it) => it === 'W') &&
          completeTree.pollinationType.some((it) => it === 'CP')
            ? ' (downwind)'
            : ''}
        </h2>
        <div className="flex overflow-x-auto -mx-2 -my-2">
          {completeTree.varieties.map((variety) => (
            <button
              key={variety.name}
              className={clsx(
                'shadow-lg rounded-md p-8 m-2 flex flex-col items-center cursor-pointer',
                treeGuildData.guild.variety === variety.name
                  ? 'bg-secondary text-white'
                  : 'bg-white'
              )}
              type="button"
              onClick={() => chooseVariety(treeGuildData.guild, variety)}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center text-xl">{variety.name}</div>
            </button>
          ))}
        </div>
      </>
    );
  }

  async function chooseSoulmate(guild: TreeGuild, soulmate: string | null) {
    await treeGuildData.updateGuild({
      ...guild,
      soulmate,
    });
  }

  function renderSoulmate() {
    const treeName = treeGuildData.guild?.tree;
    const completeTree = trees.availableTrees.find((t) => t.tree === treeName);
    if (!treeName || !completeTree || !completeTree.varieties.length)
      return null;
    const variety = completeTree.varieties.find(
      (v) => v.name === treeGuildData.guild.variety
    );
    const isOnlySP =
      completeTree.pollinationType.length === 1 &&
      completeTree.pollinationType[0] === 'SP';
    const isOnlyCP =
      completeTree.pollinationType.length === 1 &&
      completeTree.pollinationType[0] === 'CP';
    if (!variety || isOnlySP) return null;

    const soulmates = [
      ...(completeTree.pollinationType.some((it) => it === 'SP') ? [null] : []),
      ...(isOnlyCP || variety.soulmates.length
        ? variety.soulmates
        : completeTree.varieties.map((v) => v.name)),
    ];

    return (
      <>
        <h2 className="text-xl font-bold mt-10 mb-4">
          Soulmate for {treeGuildData.guild.variety}
          {completeTree.pollinationMedium.some((it) => it === 'W')
            ? ' (upwind)'
            : ''}
        </h2>
        <div className="flex overflow-x-auto -mx-2 -my-2">
          {soulmates.map((soulmate) => (
            <button
              key={soulmate ?? 'None'}
              className={clsx(
                'shadow-lg rounded-md p-8 m-2 flex flex-col items-center cursor-pointer',
                treeGuildData.guild.soulmate === soulmate
                  ? 'bg-secondary text-white'
                  : 'bg-white'
              )}
              type="button"
              onClick={() => chooseSoulmate(treeGuildData.guild, soulmate)}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center text-xl">{soulmate ?? 'None'}</div>
            </button>
          ))}
        </div>
      </>
    );
  }

  function renderNextButton() {
    const treeName = treeGuildData.guild?.tree;
    const completeTree = trees.availableTrees.find((t) => t.tree === treeName);
    const noCenterPiece = !completeTree;
    const noVariety = !treeGuildData.guild?.variety;
    const noSoulmate = treeGuildData.guild?.soulmate === undefined;
    const enabled =
      !noCenterPiece &&
      (!completeTree.varieties.length ||
        (!noVariety &&
          completeTree.pollinationType.some((it) => it === 'SP')) ||
        !noSoulmate);

    return (
      <div className="flex w-full justify-center mt-10">
        {enabled ? (
          <Button
            type="submit"
            color="secondary"
            className="flex items-center"
            onClick={() =>
              navigate(
                `/tree-guild-companion-plants/?guildId=${treeGuildData.guild.id}`
              )
            }
          >
            Configure companion plants
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </Button>
        ) : (
          <div className="text-center">
            Please select{' '}
            {noCenterPiece
              ? 'a center piece'
              : noVariety
              ? 'a Variety'
              : 'a soulmate'}{' '}
            to continue
          </div>
        )}
      </div>
    );
  }

  const title = isNewGuild
    ? 'New Tree Guild'
    : `${treeGuildData.guild.tree} Guild`;
  return (
    <Layout className="relative">
      <SEO url="/tree-guild/" title={title} description={title} />

      <div className="container m-auto">
        <h1 className="text-3xl text-center font-bold w-full mt-10 mb-4">
          {title}
        </h1>
        <Breadcrumbs
          links={[{ src: '/tree-guilds/', text: 'My Tree Guilds' }]}
          currentPage={title}
        />

        {renderCenterPiece()}
        {renderVariety()}
        {renderSoulmate()}
        {renderNextButton()}

        <div className="mb-10" />
      </div>
    </Layout>
  );
};

export default TreeGuildPage;
